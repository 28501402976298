import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  courierAbortAdminOpenState,
  courierDeliveredAdminOpenState,
  courierListAdminState,
  deliveredItemState,
  courierAbortAdminIDState,
} from "../../../atoms/courierRegistration";
import { TouchApp } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Errors from "../../../utils/Errors";
import NavigateParcelAdmin from "../../../utils/NavigateParcelAdmin";
import FormatDateHour from "../../../utils/FormatDateHour";
// import FormatDateHour from "../../../utils/FormatDateHour";

const CourierListItemAdmin = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState("");

  const [courierListAdmin, setCourierListAdmin] = useRecoilState(
    courierListAdminState
  );
  const [, setCourierAbortAdminOpen] = useRecoilState(
    courierAbortAdminOpenState
  );

  const [, setCourierAbortAdminID] = useRecoilState(courierAbortAdminIDState);

  const [, setCourierDeliveredAdminOpen] = useRecoilState(
    courierDeliveredAdminOpenState
  );
  const [, setDeliveredItem] = useRecoilState(deliveredItemState);

  const handleRequest = useCallback(
    async (i) => {
      setLoading(true);
      await axios
        .post(`booking/update/${item.id}`, {
          status: i,
        })
        .then((response) => {
          toast.success(response.data.message);
          setCourierListAdmin([
            response.data.data,
            ...courierListAdmin.filter((e) => e.id !== item.id),
          ]);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
      setOpen(false);
    },
    [item, setCourierListAdmin, courierListAdmin]
  );

  const handleTakeItems = useCallback(() => {
    setDeliveredItem(item);
    setCourierDeliveredAdminOpen(true);
  }, [setCourierDeliveredAdminOpen, setDeliveredItem, item]);

  const handleAbortCourierRegistration = useCallback(() => {
    setCourierAbortAdminOpen(true);
    setCourierAbortAdminID(item.id);
  }, [setCourierAbortAdminOpen]);

  const handleOpenDialog = (text) => {
    handleDialog(text);
    setOpen(true);
  };
  const [info, setInfo] = useState(false);

  const handleOpenInfo = useCallback((text) => {
    getParcel();
    setInfo(true);
  }, []);

  const handleDialog = useCallback((text) => {
    setDialogText(text);
  }, []);

  const approveDialog = useCallback(() => {
    if (dialogText === "Kaydı Onayla") {
      handleRequest(1);
    } else if (dialogText === "Kurye Yolla") {
      handleRequest(2);
    } else if (dialogText === "Tamamla") {
      handleRequest(4);
      // window.location.reload();
    }
  }, [handleRequest, dialogText]);
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  const [parcelInfo, setParcelInfo] = useState([]);

  const getParcel = useCallback(async () => {
    await axios
      .get(`booking`, {
        Authorization: `Bearer ${jwt}`,
      })
      .then((response) => {
        setParcelInfo(response.data.data);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [info]);

  const filteredParcelInfo = (id) => {
    const MatchedData = parcelInfo?.filter((order, index) => order.id === id);
    const parcels =
      Number(MatchedData[0]?.status) === 3 ||
      Number(MatchedData[0]?.status) === 4
        ? MatchedData[0]?.received_parcels
        : MatchedData[0]?.will_be_delivered_parcels;

    const infoParcels = parcels?.map((item, index) => (
      <div
        key={index}
        style={{
          border: " 1px solid var(--inputBorderColor)",
          borderRadius: "var(--inputBorderRadius)",
          padding: "var(--gap)",
          marginTop: "5px",
          display: " grid",
          gridTemplateColumns: " repeat(auto-fill, minmax(220px, 1fr))",
        }}
      >
        <div style={{ fontWeight: "700" }}>
          <NavigateParcelAdmin
            parcel={item?.parcel_ref_no}
            status={item?.status}
            fontSize={14}
          />
        </div>
        <div>Ücretlendirilen Ağırlık : {item?.cw}</div>
        <div>Brüt Ağırlık : {item?.gw}</div>
        <div>Hacim Desi: {item?.vw}</div>
        <div>Adet : {item?.qty}</div>
      </div>
    ));
    return infoParcels;
  };

  return (
    <Box className="courier-list-item">
      <Box
        sx={{
          width: "900px",
        }}
      >
        <Dialog
          open={open}
          onClose={(e) => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <img
            style={{ width: "24px", margin: " 10px auto 0" }}
            src="/images/logo/logo_white_mini.svg"
            alt="IyziShip Icon"
          />
          <DialogTitle id="alert-dialog-title">{dialogText}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Kaydı tamamlamak için Onayla butonuna basınız.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ dispay: "flex", gap: "var(--gap)" }}>
            <Button
              sx={{
                textTransform: "none",
                fontSize: "13px",
                color: "var(--priceText)",
                borderRadius: "var(--buttonBorderRadius)",
                backgroundColor: "var(--lighterBg)",
                border: "1px solid var(--inputBorderColor)",
                ":hover": {
                  backgroundColor: "var(--inputBorderColor)",
                },
              }}
              fullWidth
              onClick={() => setOpen(false)}
            >
              İptal
            </Button>
            {loading ? (
              <Box
                sx={{
                  maxWidth: "187px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    width: "24px!important",
                    height: "24px!important",
                  }}
                />
              </Box>
            ) : (
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "13px",
                  color: "var(--lighterBg)",
                  backgroundColor: "var(--info)",
                  border: "1px solid var(--info)",
                  borderRadius: "var(--buttonBorderRadius)",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                    border: "1px solid var(--blueBrand)",
                  },
                }}
                fullWidth
                onClick={approveDialog}
              >
                Onayla
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Box className="courier-list-item-detail-wrapper">
          <Box className="courier-list-item-detail">
            <Box className="delivery-top">
              <Box className="delivery-code">
                Teslimat Kodu:{" "}
                <span className="delivery-code-span">{item.booking_code}</span>
              </Box>
              <Box className="registration-date">
                Oluşturma Tarihi:{" "}
                <span className="registration-date-span">
                  {FormatDateHour(item.created_at)}
                </span>
              </Box>
            </Box>
            <Box className="delivery-address">
              Teslimat Adresi:{" "}
              <span className="delivery-address-span">
                {item?.get_address?.address1}{" "}
                {item?.get_address?.address2 &&
                  ` - ${item?.get_address?.address2}`}
                {item?.get_address?.address3 &&
                  ` - ${item?.get_address?.address3}`}
              </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                fontSize: "13px",
                color: "var(--priceText)",
                fontWeight: 700,
              }}
            >
              Toplama Tarihi:
              <span style={{ fontSize: "13px", fontWeight: 400 }}>
              <span className="registration-date-span">{FormatDateHour(item.status < 3 ? item.booking_date : item.updated_at)}</span>
              </span>
            </Box>
            <Box className="delivery-top">
              <Box className="registration-date">
                Gönderici Adı:{" "}
                <span className="registration-date-span">
                  {item.get_member?.name}
                </span>
              </Box>
            </Box>

            <Box className="delivery-top">
              <Box className="registration-date">
                Kontak İsim:{" "}
                <span className="registration-date-span">{item.submitter}</span>
              </Box>
              <Box className="registration-date">
                Brüt Ağırlık:{" "}
                <span className="registration-date-span">{item.total_gw}</span>
              </Box>
              <Box className="registration-date">
                Telefon Numarası:{" "}
                <span className="registration-date-span">
                  +{item.phone.slice(0, 2)} {item.phone.slice(2, 5)}{" "}
                  {item.phone.slice(5, 8)} {item.phone.slice(8, 10)}{" "}
                  {item.phone.slice(10, 12)}
                </span>
              </Box>
            </Box>
          </Box>
          {Number(item.status) !== 5 && (
            <Box className="courier-list-item-buttons">
              <Box className="courier-list-item-button-wrapper">
                <Box className="courier-list-item-button">
                  {Number(item.status) === 0 ? (
                    <>
                      <Box
                        onClick={() => handleOpenDialog("Kaydı Onayla")}
                        className="clck"
                      >
                        <TouchApp
                          className="clck-btn"
                          sx={{ color: "var(--lighterBg)" }}
                        />
                      </Box>
                      Kaydı Onayla
                    </>
                  ) : (
                    <>
                      <CheckCircleIcon
                        className="set-btn"
                        sx={{ color: "var(--greenBrand)" }}
                      />
                      Kayıt Onaylandı
                    </>
                  )}
                </Box>

                <Box className="courier-list-item-button">
                  {Number(item.status) < 2 && (
                    <>
                      <Box
                        onClick={() => handleOpenDialog("Kurye Yolla")}
                        className="clck"
                      >
                        <TouchApp
                          className="clck-btn"
                          sx={{ color: "var(--lighterBg)" }}
                        />
                      </Box>
                      Kurye Yolla
                    </>
                  )}
                  {Number(item.status) > 1 && (
                    <>
                      <CheckCircleIcon
                        className="set-btn"
                        sx={{ color: "var(--greenBrand)" }}
                      />
                      Kurye Yolda
                    </>
                  )}
                </Box>
              </Box>
              <Divider className="courier-list-item-divider" />
              <Box className="courier-list-item-button-wrapper">
                {Number(item.status) > 1 && (
                  <Box className="courier-list-item-button">
                    {Number(item.status) < 3 && (
                      <>
                        <Box
                          onClick={handleTakeItems}
                          className="clck"
                          sx={{ color: "var(--lighterBg)" }}
                        >
                          <TouchApp className="clck-btn" />
                        </Box>
                        Teslim Al
                      </>
                    )}
                    {Number(item.status) > 2 && (
                      <>
                        <CheckCircleIcon
                          className="set-btn"
                          sx={{ color: "var(--greenBrand)" }}
                        />
                        Teslim Alındı
                      </>
                    )}
                  </Box>
                )}
                {Number(item.status) > 2 && (
                  <Box className="courier-list-item-button">
                    {Number(item.status) === 3 && (
                      <>
                        <Box
                          onClick={() => handleOpenDialog("Tamamla")}
                          className="clck"
                          sx={{ color: "var(--lighterBg)" }}
                        >
                          <TouchApp className="clck-btn" />
                        </Box>
                        Tamamla
                      </>
                    )}
                  </Box>
                )}
                {Number(item.status) < 3 && (
                  <Box className="courier-list-item-button">
                    <Box
                      onClick={handleAbortCourierRegistration}
                      className="clck abort"
                    >
                      <TouchApp
                        className="clck-btn"
                        sx={{ color: "var(--lighterBg)" }}
                      />
                    </Box>
                    Kayıt İptal Et
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Box className="courier-list-item-confirm-admin">
          <Box
            className={
              Number(item.status) === 0
                ? "delivery-status waiting"
                : Number(item.status) === 1 ||
                  Number(item.status) === 3 ||
                  Number(item.status) === 4
                ? "delivery-status active"
                : Number(item.status) === 2
                ? "delivery-status on-the-way"
                : "delivery-status closed"
            }
          >
            {Number(item.status) === 0
              ? "Onay Bekleniyor"
              : Number(item.status) === 1
              ? "Kayıt Onaylandı"
              : Number(item.status) === 2
              ? "Kurye Yola Çıktı"
              : Number(item.status) === 3
              ? "Teslim Alındı"
              : Number(item.status) === 4
              ? "Tamamlandı"
              : Number(item.status) === 5
              ? "İptal Edildi"
              : ""}
          </Box>
          <Box className="delivery-code">
            Teslim Alınan Gönderi Sayısı:{" "}
            <span className="delivery-code-span">{item.qty}</span>
          </Box>
          <Dialog
            open={info}
            onClose={(e) => setInfo(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <img
              style={{ width: "24px", margin: " 10px auto 0" }}
              src="/images/logo/logo_white_mini.svg"
              alt="IyziShip Icon"
            />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {filteredParcelInfo(item.id)}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ dispay: "flex", gap: "var(--gap)" }}>
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "13px",
                  color: "var(--priceText)",
                  borderRadius: "var(--buttonBorderRadius)",
                  backgroundColor: "var(--lighterBg)",
                  border: "1px solid var(--inputBorderColor)",
                  ":hover": {
                    backgroundColor: "var(--inputBorderColor)",
                  },
                }}
                fullWidth
                onClick={() => setInfo(false)}
              >
                İptal
              </Button>
            </DialogActions>
          </Dialog>
          <>
            <Box
              onClick={() => handleOpenInfo()}
              className="clck"
              style={{
                border: "1px solid var(--priceText)",
                borderRadius: " 14px",
                display: "flex",
                justifyContent: " center",
                textAlign: "center",
                padding: "7px",
                backgroundColor: " var(--info)",
                color: " #fff",
                cursor: "pointer",
              }}
            >
              Parcel Bilgisi
            </Box>
          </>
        </Box>
        {(Number(item.status) === 3 || Number(item.status) === 4) && (
          <Box
            sx={{ display: "flex", gap: "var(--gap2x)" }}
            className="delivery-top"
          >
            <Box className="registration-date">
              Teslim Alan:{" "}
              <span className="registration-date-span">
                {item.get_receiver?.name} {item.get_receiver?.surname}
              </span>
            </Box>
            <Box className="registration-date">
              Teslim Eden:{" "}
              <span className="registration-date-span">{item.submitter}</span>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        className="delivery-code"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <p>Kurye Kaydına Ait Teslim Alınan Yükler</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.received_parcels.map((item, index) => (
            <span
              style={{
                fontSize: "12px",
              }}
              key={index}
            >
              {item.parcel_ref_no}
            </span>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default CourierListItemAdmin;
