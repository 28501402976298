import "../../../style/operation/Operation.css";
import {
  Box,
  Checkbox,
  FormControlLabel,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import OperationCargoDom from "./operationCargoEntranceWarehouse/OperationCargoDom"; 
import WarehouseOutlinedIcon from "../../../img/images/la_warehouse.svg";
import questionIcon from "../../../img/images/ph_question-bold.svg";

import OperationCargoDomResp from "./operationCargoEntranceWarehouse/OperationCargoDomResp";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ffdCheckCourierState,
  ffdCheckDomState,
  ffdCheckFrontDeskState,
  ffdCheckHandState,
  ffdDataState,
  ffdManualDomSaveToDimension,
  ffdCheckSingleRXState,
  ffdCheckMultipleRXState,
  ffdCheckSearchRXState,
  ffdAddDimensionsState,
  ffdSaveDomesticInfoState,
  ffdCheckFrontInfoRadioState,
  cargoDomStatusAlertState,
} from "../../../atoms/operation";
import { courierStatusState } from "../../../atoms/myShipments";
import OperationSingleAndFrontDeskResp from "./OperationSingleAndFrontDeskResp";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NotificationBar from "../sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const OperationCargoEntranceWarehouse = () => {
  DynamicTitle("Depo Yük Girişi - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [checkedDom, setCheckedDom] = useState(true);
  const [checkedFrontDesk, setCheckedFrontDesk] = useState(false);
  const [checkedHand, setCheckedHand] = useState(false);
  const [checkedCourier, setCheckedCourier] = useState(false);

  const [checkedFrontInfo, setCheckHandFrontInfo] = useRecoilState(
    ffdCheckFrontInfoRadioState
  );

  //tekli gonderi coklu gonderi ve gonderi arama//
  const [checkedMultipleDom, setCheckedMultipleDom] = useRecoilState(
    ffdCheckMultipleRXState
  );
  const [checkedSearchDom, setCheckedSearchDom] = useRecoilState(
    ffdCheckSearchRXState
  );
  const [checkedSingleDom, setCheckedSingleDom] = useRecoilState(
    ffdCheckSingleRXState
  );
  //tekli gonderi coklu gonderi ve gonderi arama//

  // Ölçü inputları
  const [, setAddDimensions] = useRecoilState(ffdAddDimensionsState);

  const [loading, setLoading] = useState(false);

  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const drawerOpen = useRecoilValue(drawerOpenState);
  const [cargoDomStatusAlert, setCargoDomStatusAlert] = useRecoilState(
    cargoDomStatusAlertState
  );

  const [, setCheckedDomState] = useRecoilState(ffdCheckDomState);
  const [, setCheckedFrontDeskState] = useRecoilState(ffdCheckFrontDeskState);
  const [, setCheckedHandState] = useRecoilState(ffdCheckHandState);
  const [, setCheckedCourierState] = useRecoilState(ffdCheckCourierState);
  const [, setcourierStatusState] = useRecoilState(courierStatusState);

  const [, setDomSaveToDimension] = useRecoilState(ffdManualDomSaveToDimension);
  const [domSaveInfo, setDomSaveInfo] = useRecoilState(
    ffdSaveDomesticInfoState
  );

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const handleCheckedInfoDom = (event) => {
    if (!event.target.checked) {
      setDomSaveInfo(false);
    } else {
      setDomSaveInfo(true);
    }
  };

  const handleCheckedDom = () => {
    setCheckedDom(true);
    setCheckedHand(false);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);
    // setAddDimensions(true);

    setCheckedDomState(true);
    setCheckedFrontDeskState(false);
    setCheckedHandState(false);
    setCheckedCourierState(false);

    setDomSaveToDimension(false);
    setCheckedSearchDom(false);
    setFfdData({});
  };

  const handleCheckedHand = () => {
    setCheckedDom(false);
    setCheckedHand(true);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);
    setAddDimensions(false);

    setCheckedDomState(false);
    setCheckedFrontDeskState(false);
    setCheckedCourierState(false);
    setCheckedHandState(true);

    setDomSaveToDimension(true);
    setCheckedSearchDom(false);
    setFfdData({});
    setCargoDomStatusAlert(false);
  };

  const handleCheckedFrontDesk = () => {
    setCheckedDom(false);
    setCheckedHand(false);
    setCheckedCourier(false);
    setCheckedFrontDesk(true);

    setAddDimensions(false);

    setCheckedDomState(false);
    setCheckedFrontDeskState(true);
    setCheckedHandState(false);
    setCheckedCourierState(false);

    setDomSaveToDimension(true);
    setCheckedSearchDom(false);

    setCheckHandFrontInfo(false);
    setFfdData({});
    setCargoDomStatusAlert(false);
  };

  const handleCheckedCourier = () => {
    setCheckedDom(false);
    setCheckedHand(false);
    setCheckedCourier(true);
    setCheckedFrontDesk(false);

    setAddDimensions(false);

    setCheckedDomState(false);
    setCheckedFrontDeskState(false);
    setCheckedHandState(false);
    setCheckedCourierState(true);

    setDomSaveToDimension(true);

    setCheckedSearchDom(false);
    setFfdData({});
    setCargoDomStatusAlert(false);
  };

  const handleCheckedSingleDom = () => {
    setCheckedSingleDom(true);
    setCheckedMultipleDom(false);
    setCheckedSearchDom(false);
    setAddDimensions(false);

    setCheckedDom(true);
    setCheckedHand(false);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);

    setCheckedDomState(true);
    setCheckedFrontDeskState(false);
    setCheckedHandState(false);
    setCheckedCourierState(false);
    setFfdData({});
  };

  const handleCheckedMultipleDom = () => {
    setCheckedMultipleDom(true);
    setCheckedSingleDom(false);
    setCheckedSearchDom(false);
    setAddDimensions(true);
    setFfdData({});
  };
  const handleCheckedSearch = () => {
    setCheckedSearchDom(true);
    setAddDimensions(false);

    setCheckedMultipleDom(false);
    setCheckedSingleDom(false);
    setCheckedDom(false);
    setCheckedHand(false);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);

    setCheckedDomState(false);
    setCheckedFrontDeskState(false);
    setCheckedHandState(false);
    setCheckedCourierState(false);
    setFfdData({});
  };
  // if (
  //   (ffdData?.status === "1.00" &&
  //     checkedSingleDom &&
  //     (checkedDom || checkedHand)) ||
  //   checkedSearchDom
  // ) {
  //   setcourierStatusState(true);
  // } else {
  //   setcourierStatusState(false);
  // }
  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="operation"
        style={{
          paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <div>
            <div
              style={{
                height: "90px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#ffff",
                marginBottom: "10px",
                paddingLeft: drawerOpen ? "285px" : "20px",
                transition: "all 150ms linear",
              }}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={WarehouseOutlinedIcon}
                alt="Icon"
              />
              <h6
                style={{
                  color: "var(--priceText)",
                }}
              >
                Mal Kabul
              </h6>
              <div
                style={{
                  width: "193px",
                  Height: "32px",
                  border: " 1px solid #4285F4",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  color: " rgb(66, 133, 244)",
                  marginLeft: "10px",
                  gap: "5px",
                  cursor: "pointer",
                }}
              >
                <img src={questionIcon} alt="question icon" />
                Bu Sayfa Nasıl Kullanılır
              </div>
            </div>
            <Box className="operation-wrapper" style={WrapperStyle()}>
              <animated.div ref={ref} style={springs}>
                <Box className="operation-container">
                  <div className="operation-freight-front-desk">
                    <div className="operation-freight-front-desk-checkbox">
                      <div className="operation-freight-front-desk-checkbox-tics">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedSingleDom}
                              onChange={handleCheckedSingleDom}
                            />
                          }
                          label="Tekli Gönderi"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedMultipleDom}
                              onChange={handleCheckedMultipleDom}
                            />
                          }
                          label="Çoklu Gönderi"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedSearchDom}
                              onChange={handleCheckedSearch}
                            />
                          }
                          label="Gönderi Arama"
                        />
                        {/*  <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedOutlinedIcon />}
                            checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                            checked={checkedTicket}
                            onChange={() => setCheckedTicket(!checkedTicket)}
                          />
                        }
                        label="Etiket Yazdır"
                      /> */}
                      </div>
                      <div className="operation-freight-front-desk-checkbox-doms">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedDom}
                              onChange={handleCheckedDom}
                            />
                          }
                          label="Domestik Teslim"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedFrontDesk}
                              onChange={handleCheckedFrontDesk}
                            />
                          }
                          label="Ön Büro Elden Teslim"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedHand}
                              onChange={handleCheckedHand}
                            />
                          }
                          label="Yük Depo Elden Teslim"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedCourier}
                              onChange={handleCheckedCourier}
                            />
                          }
                          label="Yük Kuryeden Teslim"
                        />
                      </div>
                      {checkedDom && checkedSingleDom && (
                        <div className="operation-freight-front-desk-checkbox-doms">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedOutlinedIcon />}
                                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                                checked={domSaveInfo}
                                onChange={(event) =>
                                  handleCheckedInfoDom(event)
                                }
                              />
                            }
                            label="Domestik Bilgi"
                          />
                        </div>
                      )}
                    </div>
                    {(checkedDom ||
                      checkedFrontDesk ||
                      checkedHand ||
                      checkedCourier) && <OperationCargoDom />}
                    {/*/checkedFrontDesk && <OperationCargoFrontDesk />/*/}
                    {/*/checkedHand && <OperationCargoHand />/*/}
                    {/*/checkedCourier && <OperationCargoCourier />/*/}
                  </div>
                  {/*    {Object.keys(ffdData).length > 0 && (
                    <div className="operation-response">
                      <OperationCargoDomResp />
                    </div>
                  )} */}

                  <div>
                    {Object.keys(ffdData).length > 0 &&
                      ((checkedSingleDom &&
                        (checkedDom || checkedCourier || checkedHand)) ||
                        checkedSearchDom) && (
                        <div className="operation-response">
                          <OperationCargoDomResp />
                        </div>
                      )}
                  </div>
                  <div>
                    {cargoDomStatusAlert && checkedMultipleDom && (
                      <div className="operation-response">
                        Gönderi Statüsü 2.01 e çekildi.
                      </div>
                    )}
                  </div>

                  {/*/status === "2.01" && (
                    <div className="operation-freight-front-desk">
                      <p>
                        Gönderinin depo girişi <span>{formattedDate} saat {formattedTime}{" "}</span>
                        tarihinde yapılmıştır. Lütfen Gönderi arama
                        seçeneğinden işlemlerinize devam ediniz.
                      </p>
                    </div>
                  )/*/}
                  {/*/status !== "2.01" && (
                    <div>
                      {Object.keys(ffdData).length > 0 &&
                        ((checkedSingleDom &&
                          (checkedDom || checkedCourier || checkedHand)) ||
                          checkedSearchDom) && (
                          <div className="operation-response">
                            <OperationCargoDomResp />
                          </div>
                        )}
                      {Object.keys(ffdData).length > 0 &&
                        checkedSingleDom &&
                        checkedFrontDesk && (
                          <div className="operation-response">
                            <OperationSingleAndFrontDeskResp />
                          </div>
                        )}
                    </div>
                  )/*/}
                </Box>
              </animated.div>
            </Box>
          </div>
        )}
      </section>
    </ThemeProvider>
  );
};

export default OperationCargoEntranceWarehouse;
